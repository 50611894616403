import { fetchWrapper } from "@/helpers";
const ruta = process.env.VUE_APP_ROOT_API;

export default class BitacoraService {
  async obtenerListadoAgenda(data) {
    return await fetchWrapper.post(`${ruta}/agendas/listado`, data);
  }

  async saveAgenda(datos) {
    return await fetchWrapper.post(`${ruta}/agendas/crear`, datos);
  }

  async deleteAgenda(id) {
    return await fetchWrapper.delete(`${ruta}/agendas/eliminar/${id}`);
  }

  async updateEstadoAgenda(id, estado) {
    return await fetchWrapper.put(`${ruta}/agendas/actualizar/${id}`, {
      estado,
    });
  }
}
