<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >CREMATORIO</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Agenda Cineris</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex items-center flex-col lg:flex-row lg:justify-between border-2 border-gray-300 rounded-lg p-4 bg-gray-100"
    >
      <div>
        <div
          class="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800 mb-2 lg:mb-0"
        >
          Agenda Cineris
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0 ml-4">
        <span class="text-lg font-semibold text-gray-600 mr-2 lg:mr-4"
          >Fecha:</span
        >
        <span class="text-xl lg:text-4xl font-bold text-gray-800">{{
          convertirFecha(fechaSelected)
        }}</span>
      </div>
    </div>

    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="col-12 md:col-4">
          <div class="field col-12 md:col-12">
            <Calendar
              v-model="fechaSelected"
              :inline="true"
              class="p-inputtext-sm"
              locale="es"
              dateFormat="dd/mm/yy"
              @date-select="obtenerListadoAgenda()"
            >
            </Calendar>
          </div>
        </div>
        <div class="col-12 md:col-8">
          <div class="p-fluid formgrid grid" v-show="false">
            <div class="field col-12 md:col-6">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon mb-1">
                  <i class="pi pi-user"></i>
                </span>
                <span class="p-float-label">
                  <AutoComplete
                    v-model="clienteSelected"
                    :suggestions="clientes"
                    field="nombre"
                    class="p-inputtext-sm mb-1"
                    v-tooltip.top="'Buscar paciente'"
                    @keyup.enter="buscarCliente"
                    autofocus
                  />
                  <label class="text-gray-600">Buscar paciente</label>
                </span>
                <Button
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-outlined p-button-sm ml-1"
                  v-tooltip.top="'Agregar paciente'"
                  @click="activarCliente"
                />
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon mb-1">
                  <i class="pi pi-user"></i>
                </span>
                <Dropdown
                  v-model="medicoSelected"
                  :disabled="this.existe_medico_logueado"
                  :options="medicos"
                  optionLabel="nombre_completo"
                  optionValue="id"
                  placeholder="SELECCIONE UN MÉDICO"
                  class="p-inputtext-sm mb-1"
                  :filter="true"
                  v-tooltip.top="'Seleccione un médico'"
                />
                <Button
                  label="MOSTRAR"
                  icon="pi pi-search"
                  class="p-button-sm p-button-outlined p-button-primary ml-2 mb-1"
                  v-tooltip.top="'Mostrar turnos'"
                  @click="obtenerListadoAgenda"
                />
              </div>
            </div>
          </div>
          <DataTable
            :value="turno_mañana"
            class="p-datatable-sm"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="280px"
            editMode="cell"
            @cell-edit-complete="saveChangeCell"
            :rowhover="true"
            stripedRows
            showGridlines
            selectionMode="single click"
            :loading="loading"
          >
            <template #loading
              ><div class="flex align-items-center justify-content-center">
                <ProgressSpinner /></div
            ></template>
            <template #header
              ><i class="pi pi-calendar"></i>
              <span class="font-bold text-gray-800">TURNOS</span></template
            >

            <Column
              field="hora"
              header="HORA"
              style="font-weight: bold; color: #000"
            >
              <template #body="slotProps">
                <span>{{ slotProps.data.hora }}</span>
              </template>
            </Column>
            <Column field="nombre_medico" header="FAMILIAR"> </Column>
            <Column
              field="nombre_consultorio"
              header="UBICACION"
              class="bg-blue-50"
              style="font-weight: bold; color: #000"
            >
              <template #editor="{ data, field }">
                <Dropdown
                  :disabled="data.estado === 2"
                  v-model="data[field]"
                  :options="consultorios"
                  optionLabel="nombre_consultorio"
                  optionValue="id"
                  placeholder="Seleccione un consultorio"
                  class="p-inputtext-sm"
                  :filter="true"
                />
              </template>
            </Column>
            <Column field="nombre_paciente" header="DIFUNTO"> </Column>
            <Column
              field="detalle"
              header="DETALLE"
              class="bg-yellow-50"
              style="font-weight: bold; color: #000"
            >
            </Column>
            <Column field="estado" header="ESTADO">
              <template #body="slotProps">
                <span v-if="slotProps.data.estado === 1" class="fondo-pendiente"
                  >SERVICIO PAGADO Y PROGRAMADO</span
                >
                <span v-if="slotProps.data.estado === 2" class="fondo-info"
                  >PAGO PARCIAL - SERVICIO PROGRAMADO</span
                >
                <span v-if="slotProps.data.estado === 3" class="fondo-info"
                  >SERVICIO RATIFICADO E INICIADO</span
                >
                <span v-if="slotProps.data.estado === 4" class="fondo-info"
                  >SERVICIO FACTURADO Y FINALIZADO
                </span>
                <span
                  v-if="slotProps.data.estado === 5"
                  class="fondo-finalizado"
                  >SERVICIO CONCLUIDO
                </span>
              </template>
            </Column>
            <!-- <Column header="ACCIONES" v-show="false">
              <template #body="slotProps">
                <Button
                  v-if="slotProps.data.estado === undefined"
                  :disabled="this.medicoSelected === 0"
                  icon="pi pi-save"
                  class="p-button-rounded p-button-success p-button-outlined p-button-sm mr-1"
                  @click="saveAgenda(slotProps)"
                  v-tooltip.top="'Guardar cambios'"
                />
                <Button
                  v-if="slotProps.data.estado === 1"
                  :disabled="this.medicoSelected === 0"
                  icon="pi pi-check"
                  class="p-button-rounded p-button-outlined p-button-primary p-button-sm mr-1"
                  v-tooltip.top="'Atender turno'"
                  @click="goToProcedimiento(slotProps.data)"
                />
                <Button
                  :disabled="slotProps.data.estado === undefined"
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-outlined p-button-danger p-button-sm"
                  v-tooltip.top="'Eliminar registro'"
                  @click="deleteAgenda(slotProps.data.id)"
                />
              </template>
            </Column> -->
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <ClienteCreate
    :show="clienteDialog"
    :cliente="cliente"
    @actualizarListado="obtenerClientes"
    @closeModal="cerrarClienteDialog"
  />
</template>
<script>
import AgendaService from "@/service/AgendaService";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import ClienteService from "@/service/ClienteService";
import { useAuth } from "@/stores";
import MedicoService from "@/service/MedicoService";

export default {
  components: {
    ClienteCreate,
  },
  data() {
    return {
      fechaSelected: new Date(),
      turno_mañana: [
        { hora: "08:00", item: 1 },
        { hora: "11:00", item: 2 },
        { hora: "14:00", item: 3 },
        { hora: "17:00", item: 4 },
        { hora: "20:00", item: 5 },
        { hora: "23:00", item: 6 },
      ],
      agendas: [],
      medicos: [{ id: 0, nombre_completo: "TODOS" }],
      medicoSelected: 0,
      consultorios: [],
      estados: [
        { label: "Pendiente", value: 1 },
        { label: "Confirmado", value: 2 },
        { label: "Cancelado", value: 0 },
      ],
      loading: false,
      clienteDialog: false,
      clientes: [],
      pacientes: [],
      cliente: {},
      clienteSelected: null,
      existe_medico_logueado: false,
    };
  },
  agendaService: null,
  auth: null,
  clienteService: null,
  medicoService: null,

  created() {
    this.agendaService = new AgendaService();
    this.clienteService = new ClienteService();
    this.medicoService = new MedicoService();
    this.auth = useAuth();
  },

  mounted() {
    this.obtenerListadoAgenda();
  },

  methods: {
    buscarCliente() {
      let datos = {
        texto: this.clienteSelected,
      };
      this.clienteService.buscarXNombre(datos).then((response) => {
        if (response.status == 400 || response.clientes.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: "ERROR",
            detail: "No se encontró el cliente",
            life: 300,
          });
          return;
        }
        this.clientes = [...response.clientes];
      });
    },
    activarCliente() {
      this.clienteDialog = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerrarClienteDialog() {
      this.clienteDialog = false;
    },

    obtenerClientes(selected = null) {
      this.clienteService
        .getClientesAll()
        .then((response) => {
          if (selected) {
            this.clienteSelected = selected;
            this.clientes = [];
          } else {
            this.clientes = response;
          }
        })

        .catch((error) => console.log(error));
    },

    deleteAgenda(id) {
      this.loading = true;
      this.$confirm.require({
        header: "CONFIRMACIÓN",
        message: "¿ESTÁS SEGURO DE ELIMINAR ESTE REGISTRO?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Guardar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        closable: false,
        closeOnEscape: false,
        accept: () => {
          this.agendaService.deleteAgenda(id).then((data) => {
            if (data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: data.message,
              });
              this.obtenerListadoAgenda();
              this.loading = false;
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data.message,
              });
              this.loading = false;
            }
          });
        },
        reject: () => {
          this.loading = false;
        },
      });
    },

    goToProcedimiento(data) {
      this.$router.push({
        name: "plantratamientos",
        query: {
          id: data.id,
          cliente_id: data.paciente_id,
          nombre_paciente: data.nombre_paciente,
          medico_id: data.medico_id,
          nombre_medico: data.nombre_completo,
          consultorio_id: data.consultorio_id,
          consultorio_nombre: data.nombre_consultorio,
          fecha_hora: data.fecha_hora,
          detalle: data.detalle,
        },
      });

      this.agendaService.updateEstadoAgenda(data.id, 2).then((data) => {
        if (data.success) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: data.message,
          });
          //this.obtenerListadoAgenda();
          this.loading = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.message,
          });
          this.loading = false;
        }
      });
    },
    mostrarTurnos() {
      console.log(this.medicoSelected);
    },
    convertirFecha(fecha) {
      return fecha.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    cargarMedicos() {
      this.medicoService.getMedicosAll().then((data) => {
        this.medicos = data.medicos || [];
        //busco el id del medico logueado
        let medico = this.medicos.find(
          (medico) => medico.nombre_completo === this.auth.user.name
        );
        if (medico) {
          this.medicoSelected = medico.id;
          this.obtenerListadoAgenda();
          this.existe_medico_logueado = true;
        } else {
          this.medicoSelected = 0;
          this.medicos.unshift({ id: 0, nombre_completo: "TODOS" });
          this.obtenerListadoAgenda();
        }
      });
    },
    obtenerListadoAgenda() {
      this.loading = true;
      this.turno_mañana = [
        { hora: "08:00", item: 1 },
        { hora: "11:00", item: 2 },
        { hora: "14:00", item: 3 },
        { hora: "17:00", item: 4 },
        { hora: "20:00", item: 5 },
        { hora: "23:00", item: 6 },
      ];

      this.turno_tarde = [{ hora: "12:00", item: 9 }];
      let datos = {
        fecha_hora: this.fechaSelected,
        medico_id: this.medicoSelected,
      };
      this.agendaService.obtenerListadoAgenda(datos).then((data) => {
        this.pacientes = data.pacientes || [];
        this.consultorios = data.consultorios || [];
        this.agendas = data.agendas || [];
        this.turno_mañana = this.turno_mañana.map((turno) => {
          let agenda = this.agendas.find(
            (agenda) => agenda.item === turno.item
          );
          if (agenda) {
            turno = { ...turno, ...agenda };
          }
          return turno;
        });
        this.turno_tarde = this.turno_tarde.map((turno) => {
          let agenda = this.agendas.find(
            (agenda) => agenda.item === turno.item
          );
          if (agenda) {
            turno = { ...turno, ...agenda };
          }
          return turno;
        });

        this.loading = false;
      });
    },
    saveChangeCell(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
      if (data[field] !== undefined && data[field] !== null) {
        if (field === "nombre_consultorio") {
          let consultorio = this.consultorios.find(
            (consultorio) => consultorio.id === newValue
          );
          if (consultorio) {
            data[field] = consultorio.nombre_consultorio;
            data.consultorio = consultorio.id;
          } else {
            console.error(
              "No se encontró el consultorio con el ID proporcionado."
            );
          }
        }
      }
    },

    validacionesFormulario(e) {
      if (this.medicoSelected === 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Seleccione un médico",
          life: 300,
        });
        return false;
      }
      if (e.data.consultorio === undefined) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Seleccione un consultorio",
          life: 300,
        });
        return false;
      }
      if (this.clienteSelected === null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Seleccione un paciente",
          life: 300,
        });
        return false;
      }
      if (e.data.detalle === undefined) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Ingrese un detalle",
          life: 300,
        });
        return false;
      }
      return true;
    },
    saveAgenda(e) {
      this.loading = true;
      if (!this.validacionesFormulario(e)) {
        this.loading = false;
        return;
      }
      let datos = {
        item: e.data.item,
        fecha_hora: this.fechaSelected,
        medico_id: this.medicoSelected,
        nombre_medico: this.medicos.find(
          (medico) => medico.id === this.medicoSelected
        ).nombre_completo,
        consultorio_id: e.data.consultorio,
        nombre_consultorio: e.data.nombre_consultorio,
        paciente_id: this.clienteSelected.id,
        nombre_paciente: this.clienteSelected.nombre ?? this.clienteSelected,
        detalle: e.data.detalle,
        estado: 1,
      };

      this.agendaService.saveAgenda(datos).then((data) => {
        if (data.success) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: data.message,
            life: 800,
          });
          this.clienteSelected = null;
          this.obtenerListadoAgenda();

          this.loading = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 800,
          });
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
//al datatable que se note las lineas de las celdas
.p-datatable {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #000;
}
.fondo-pendiente {
  text-align: center;
  background-color: yellow;
  color: #000000;
  border-radius: 5px;
}
.fondo-info {
  text-align: center;
  background-color: #cce5ff;
  color: #004085;
  border-radius: 5px;
}
.fondo-finalizado {
  text-align: center;
  background-color: #d4edda;
  color: #155724;
  border-radius: 5px;
}
</style>
